import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import {
  Container,
  Description,
  FirstPersonContainer,
  InnerPersonContainer,
  ListButton,
  ListContainer,
  ListTitle,
  Name,
  PersonImage,
  PersonInfo,
  SecondPersonContainer,
  Title,
} from './InsightsPersonsSection.styled';

const POSITION_LEFT = 'left';
const POSITION_RIGHT = 'right';

const InsightsPersonsSection = ({ personsCollections }) => {
  const firstPerson = personsCollections.find((person) => person.position === POSITION_LEFT);
  const secondPerson = personsCollections.find((person) => person.position === POSITION_RIGHT);

  const handleListButtonClick = (slug) => () => {
    navigate(`/${slug}`);
  };

  return (
    <Container>
      {/* eslint-disable-next-line react/no-danger */}
      <Title>Check our insightful content handpicked by:</Title>

      {firstPerson && (
        <FirstPersonContainer>
          <InnerPersonContainer>
            <PersonInfo>
              <Name>{firstPerson.presented_team_member.full_name}</Name>
              <Description>{firstPerson.presented_team_member.position}</Description>
            </PersonInfo>
            <PersonImage src={firstPerson.presented_team_member.torso_avatar} />
            {/* eslint-disable-next-line react/no-danger */}
            <ListContainer>
              <ListTitle dangerouslySetInnerHTML={{ __html: firstPerson.group_label }} />
              <ListButton onClick={handleListButtonClick(firstPerson.slug)}>
                {firstPerson.cta_button_text}
              </ListButton>
            </ListContainer>
          </InnerPersonContainer>
        </FirstPersonContainer>
      )}

      {secondPerson && (
        <SecondPersonContainer>
          <InnerPersonContainer>
            <PersonInfo>
              <Name>{secondPerson.presented_team_member.full_name}</Name>
              <Description>{secondPerson.presented_team_member.position}</Description>
            </PersonInfo>
            <PersonImage src={secondPerson.presented_team_member.torso_avatar} />
            {/* eslint-disable-next-line react/no-danger */}
            <ListContainer>
              <ListTitle dangerouslySetInnerHTML={{ __html: secondPerson.group_label }} />
              <ListButton onClick={handleListButtonClick(secondPerson.slug)}>
                {secondPerson.cta_button_text}
              </ListButton>
            </ListContainer>
          </InnerPersonContainer>
        </SecondPersonContainer>
      )}
    </Container>
  );
};

InsightsPersonsSection.propTypes = {
  personsCollections: PropTypes.arrayOf(
    PropTypes.shape({
      cta_button_text: PropTypes.string,
      group_label: PropTypes.string,
      presented_team_member: PropTypes.shape({
        full_name: PropTypes.string,
        position: PropTypes.string,
        torso_avatar: PropTypes.string,
      }),
      slug: PropTypes.string,
    })
  ).isRequired,
};

export default InsightsPersonsSection;
