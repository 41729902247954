import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Row,
} from 'react-awesome-styled-grid';
import styled, { ThemeProvider } from 'styled-components';
import useResizeObserver from 'use-resize-observer/polyfilled';
import mediaQueries, { breakpoints } from '@utils/media-queries';
import { debounce } from '@utils/debounce';
import InsightsGlobalStyles from '@components/Insights/InsightsGlobalStyles.styled';
import InsightsHead from '@components/Insights/InsightsHead/InsightsHead';
import {
  CollectionsCarousel,
  CommonScripts,
  GridStairs,
  GridTransparent,
  HomeHeader,
  HomepageFilters,
  InsightsPosts,
} from '@components';
import {
  headerStairsMobile,
  homeCollectionsStairs,
  homeHeaderStairs,
  homeHeaderStairsMedium,
  homeHeaderStairsMobile,
  homeLeadersStairs,
  insightsGridConfig,
} from '@utils/grid';
import {
  getOptionsByAttr,
  sortByLabel,
} from '@utils/helpers';
import { colors } from '@utils/vars';
import useInsightsFilters from '@services/useInsightsFilters';
import FallbackHeaderVideo from '@src/assets/header-video.mp4';
import BottomBar from '@components/BottomBar/BottomBar';
import InsightsPersonsSection
  from '@components/Insights/InsightsPersonsSection/InsightsPersonsSection';

import HamburgerMenu from '@components/HamburgerMenu/HamburgerMenu';
import { MenuContext } from '../components/HamburgerMenu/MenuContext';

const {
  small, medium, large, xlarge,
} = mediaQueries;

const {
  markerYellow: markerYellowColor,
} = colors;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-size: 14px;

  @media ${xlarge} {
    font-size: 13px;
  }

  @media ${large} {
    font-size: 12px;
  }

  @media ${small} {
    overflow: scroll;
  }

  small {
    font-size: smaller;
  }

  .marker {
    background: ${markerYellowColor};
  }
`;

const ContentContainer = styled.div`
  position: relative;
  padding-top: 70px;
  padding-bottom: 200px;

  @media ${xlarge} {
    padding-bottom: 100px;
  }
`;

const CollectionsContainer = styled.div`
  position: relative;
  padding-top: 239px;
  padding-bottom: 200px;
  background-image: url(${({ backgroundImage }) => backgroundImage});

  @media ${xlarge} {
    padding-top: 150px;
    padding-bottom: 150px;
  }
`;

const LeadersContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 798px;
  margin-bottom: 48px;
  padding-top: 30px;

  @media ${xlarge} {
    height: 580px;
  }

  @media ${medium} {
    height: auto;
    margin-bottom: 96px;
  }
`;

const Homepage = ({
  location,
  pageContext: {
    content,
    contentCollections,
    personsCollections,
  },
}) => {
  const {
    ref, width: windowWidth = 0,
  } = useResizeObserver();

  const {
    chat_interaction_id: chatId,
    header_image: headerImage,
    header_video: headerVideo,
    footer_image: footerImage,
    headline: title,
    description: subtitle,
    seo: meta,
    technologies,
    industries,
    content_types,
    subtopics,
    clutch,
    menu_items: menuItems,
    social_media_icons: socialItems,
    content_collections_label: contentCollectionsLabel,
  } = content;

  const parsedClutch = {
    ...clutch,
    firstText: clutch.first_text,
    reviewsUrl: clutch.reviews_url,
    secondText: clutch.second_text,
  };

  const collectionCategories = contentCollections.reduce((categories, collection) => {
    if (!categories.includes(collection.topic.name)) {
      return [
        ...categories,
        collection.topic.name,
      ];
    }

    return categories;
  }, []);

  const themesOptions = getOptionsByAttr(subtopics, 'name').sort(sortByLabel);
  const typesOptions = getOptionsByAttr(content_types, 'content_type').sort(sortByLabel);
  const industryOptions = getOptionsByAttr(industries, 'name').sort(sortByLabel);
  const technologyOptions = getOptionsByAttr(technologies, 'name').sort(sortByLabel);

  const {
    filters, changeFilter, clearFilter, availableFilterOptions,
  } = useInsightsFilters({});

  const pagePath = typeof window !== 'undefined' ?
    `${window.location.pathname}${window.location.search}` :
    `${location.pathname}${location.search}`;

  let headerStairs = homeHeaderStairsMobile;

  if (windowWidth > breakpoints.small) {
    headerStairs = homeHeaderStairsMedium;
  }

  if (windowWidth > breakpoints.medium) {
    headerStairs = homeHeaderStairs;
  }

  const checkScrollYAmount = () => window.scrollY >= 100;

  const [
    resizeClass,
    setResizeClass,
  ] = useState('');

  const addResizeClass = () => setResizeClass('resizing');
  const removeResizeClass = debounce(() => {
    setResizeClass('');
  }, 1000);

  const handleResize = () => {
    addResizeClass();
    removeResizeClass();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setDefaultOverflowValue = useCallback(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
  }, []);

  const [
    revealMenuButton,
    setRevealMenuButton,
  ] = useState(false);

  const [
    isOpenedFromNavigation,
    setOpenedFromNavigation,
  ] = useState(false);

  const showMenuBtn = useCallback(() => {
    if (isOpenedFromNavigation) {
      setRevealMenuButton(checkScrollYAmount());
      setDefaultOverflowValue();
    } else {
      setRevealMenuButton(checkScrollYAmount());
      setDefaultOverflowValue();
    }
  }, [setDefaultOverflowValue]);

  useEffect(() => {
    const onScroll = () => showMenuBtn();

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [showMenuBtn]);

  const [
    icon,
    setIcon,
  ] = useState('menu');
  const switchIcon = () => {
    setIcon((last) => (last === 'close' ? 'menu' : 'close'));
  };

  const [
    menuVisible,
    setMenuVisible,
  ] = useState(false);
  const [
    toggleMenu,
    setToggleMenu,
  ] = useState(false);

  const switchToggle = () => {
    setTimeout(() => {
      setToggleMenu(() => !menuVisible);
    }, 100);
  };

  const [
    isExpanded,
    setExpanded,
  ] = useState(false);

  const [
    activeElement,
    setActiveElement,
  ] = useState('');

  const toggleClick = (attr) => {
    setExpanded(true);
    setActiveElement(attr);
  };

  const closeExpand = () => {
    if (checkScrollYAmount()) {
      setRevealMenuButton(true);
      setOpenedFromNavigation(false);
    }
    setExpanded(false);
    setActiveElement(null);
  };

  const setOverlayValue = () => {
    document.getElementsByTagName('html')[0].style.overflowY = toggleMenu ? 'auto' : 'hidden';
  };

  const handleMenuClick = () => {
    if (!checkScrollYAmount()) {
      setRevealMenuButton(false);
    } else {
      setRevealMenuButton(true);
    }
    setMenuVisible(!menuVisible);
    switchIcon();
    switchToggle();
    setOverlayValue();
  };

  const manageMenuButtonState = () => {
    if (checkScrollYAmount()) {
      setRevealMenuButton(false);
      setOpenedFromNavigation(false);
    }
    closeExpand();
    handleMenuClick();
  };

  return (
    <MenuContext.Provider
      value={{
        isExpanded,
        isVisible: toggleMenu,
        setExpanded,
      }}
    >
      <Wrapper
        ref={ref}
        className={resizeClass}
      >
        {meta && <InsightsHead meta={meta} />}
        <CommonScripts />
        <InsightsGlobalStyles />
        <ThemeProvider theme={{ awesomegrid: insightsGridConfig }}>
          <HamburgerMenu
            activeElement={activeElement}
            closeExpand={closeExpand}
            icon={icon}
            isExpanded={isExpanded}
            manageMenuButtonState={manageMenuButtonState}
            showMenuBtn={revealMenuButton}
            toggleClick={toggleClick}
            toggleMenu={toggleMenu}
            chatId={chatId}
          />
          <HomeHeader
            image={headerImage}
            title={title}
            subtitle={subtitle}
            menuItems={menuItems}
            socialItems={socialItems}
            video={headerVideo || FallbackHeaderVideo}
            visible={toggleMenu}
            openMenu={handleMenuClick}
            setRevealMenuButton={setRevealMenuButton}
            revealMenuButton={revealMenuButton}
            setOpenedFromNavigation={setOpenedFromNavigation}
          />
          <GridStairs
            offsets={headerStairs}
          />
          <ContentContainer>
            <GridTransparent zIndex={0} />
            <HomepageFilters
              technologies={technologyOptions}
              industries={industryOptions}
              contentTypes={typesOptions}
              themes={themesOptions}
              selectedTheme={filters.theme}
              selectedTechnology={filters.technology}
              selectedContentType={filters.type}
              selectedIndustry={filters.industry}
              onFilterChange={changeFilter}
              availableFilterOptions={availableFilterOptions}
            />
            <InsightsPosts
              themes={filters.theme}
              technologies={filters.technology}
              industries={filters.industry}
              contentTypes={filters.type}
              selectedSort={filters.ordering}
              onSortChange={changeFilter}
              onFilterChange={changeFilter}
              onFilterClearClick={clearFilter}
              pagePath={pagePath}
            />
          </ContentContainer>
          <GridStairs
            colorDown="white"
            offsets={windowWidth > breakpoints.small ? homeCollectionsStairs : headerStairsMobile}
          />
          <CollectionsContainer backgroundImage={footerImage}>
            <GridTransparent zIndex={0} />
            <CollectionsCarousel
              title={contentCollectionsLabel}
              collections={contentCollections}
              categories={collectionCategories}
            />
          </CollectionsContainer>
          <GridStairs
            offsets={windowWidth > breakpoints.small ? homeLeadersStairs : headerStairsMobile}
          />
          <LeadersContainer>
            <GridTransparent />
            <Container>
              <Row>
                <Col lg={12}>
                  <InsightsPersonsSection personsCollections={personsCollections} />
                </Col>
              </Row>
            </Container>
          </LeadersContainer>
          <BottomBar clutch={parsedClutch} />
        </ThemeProvider>
      </Wrapper>
    </MenuContext.Provider>
  );
};

Homepage.propTypes = {
  location: PropTypes.shape({
    origin: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    content: PropTypes.object,
    contentCollections: PropTypes.array,
    personsCollections: PropTypes.array,
  }).isRequired,
};

export default Homepage;
