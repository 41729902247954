import { ButtonCube } from '@components/index';
import styled from 'styled-components';
import { colors } from '@utils/vars';
import mediaQueries from '@utils/media-queries';

const {
  xxsmall, xsmall, small, medium, large, xlarge,
} = mediaQueries;

export const Container = styled.div`
  position: relative;
  height: 768px;

  @media ${xlarge} {
    height: 614px;
  }

  @media ${medium} {
    height: auto;
    padding-bottom: 100px;
  }
`;

export const Title = styled.h3`
  max-width: 380px;
  margin-right: auto;
  margin-left: auto;
  font-size: 50px;
  line-height: 55px;
  text-align: center;


  @media ${xlarge} {
    max-width: 304px;
    font-size: 40px;
    line-height: 45px;
  }

  @media ${medium} {
    max-width: 380px;
    font-size: 30px;
    line-height: 45px;
  }
`;

export const PersonInfo = styled.div`
  position: absolute;
  width: 260px;
  height: 43px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    transform: translateY(-50%);
  }
`;

export const Name = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

export const PersonImage = styled.img`
  position: absolute;
  bottom: 0;
  display: block;
  max-height: 580px;
`;

export const ListContainer = styled.div`
  position: absolute;
  width: 270px;

  @media ${xlarge} {
    width: 216px;
  }

  @media ${medium} {
    width: auto;
  }
`;

export const ListTitle = styled.div`
  margin-bottom: 35px;
  font-size: 40px;
  line-height: 50px;
  text-align: center;

  strong {
    font-weight: bold;
  }

  @media ${xlarge} {
    margin-bottom: 28px;
    font-size: 32px;
    line-height: 40px;
  }

  @media ${medium} {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ListButton = styled(ButtonCube)`
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;

  @media ${xlarge} {
    font-size: 14px;
  }


  @media ${xsmall} {
    font-size: 14px;
  }

  @media ${xxsmall} {
    font-size: 12px;
  }
`;

export const InnerPersonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BasePersonContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 768px;

  && {
    @media ${xlarge} {
      height: 614px;
    }

    @media ${medium} {
      position: static;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      margin-top: 60px;

      ${PersonInfo} {
        position: relative;
        top: 0;
        left: 0;
        margin-right: auto;
        margin-bottom: 20px;
        margin-left: auto;
      }

      ${ListContainer} {
        position: static;
      }

      ${InnerPersonContainer} {
        position: static;
        width: auto;
        height: auto;
      }
    }

    @media ${small} {

      ${PersonInfo} {
         &:after {
          display: none;
         }
      }
    }
  }
`;

export const FirstPersonContainer = styled(BasePersonContainer)`
  right: calc(50% - 132px);
  left: 0;

  ${PersonInfo} {
    top: 280px;
    right: 0;

    &:after {
      left: 10px;
      border-right: 11px solid ${colors.highlight};
    }
  }

  ${ListContainer} {
    top: 250px;
    left: 77px;
  }

  ${PersonImage} {
    left: 24%;
  }

  @media ${xlarge} {
     ${PersonImage} {
        left: 24%;
        max-height: 464px;
     }

    ${PersonInfo} {
      top: 224px;
    }

    ${ListContainer} {
      top: 150px;
    }
  }

  @media (max-width: 1400px) {
     ${PersonImage} {
        left: 28%;
        max-height: 380px;
     }
  }

  @media ${large} {
     ${PersonImage} {
        left: 200px;
        max-height: 300px;
     }

     ${ListContainer} {
        left: 0;
     }
  }

  @media ${medium} {
    ${PersonImage} {
      right: 58%;
      bottom: 0;
      left: auto;
      display: block;
      max-height: 614px;
    }
  }

  @media ${small} {
    ${PersonImage} {
      display: none;
    }
  }
`;

export const SecondPersonContainer = styled(BasePersonContainer)`
  right: 0;
  left: calc(50% - 132px);

  ${PersonInfo} {
    top: 360px;
    left: 0;

    &:after {
      right: 10px;
      border-left: 11px solid ${colors.highlight};
    }
  }

  ${ListContainer} {
    top: 250px;
    right: 77px;
  }

  ${PersonImage} {
    right: 24%;
  }

  @media ${xlarge} {
     ${PersonImage} {
        right: 24%;
        max-height: 464px;
     }

    ${PersonInfo} {
      top: 288px;
    }

    ${ListContainer} {
      top: 150px;
    }
  }

  @media (max-width: 1400px) {
     ${PersonImage} {
        right: 24%;
        max-height: 380px;
     }
  }

  @media ${large} {
     ${PersonImage} {
        right: 24%;
        max-height: 300px;
     }

     ${ListContainer} {
        right: 0;
     }
  }

  @media ${medium} {
    ${PersonImage} {
      right: auto;
      bottom: 0;
      left: 58%;
      display: block;
      max-height: 580px;
    }
  }

  @media ${small} {
    ${PersonImage} {
      display: none;
    }
  }
`;
